import React from "react"
import { Nav, Navbar } from "react-bootstrap"
import "./static/bootstrap/bootstrap431dist/css/bootstrap.min.css"
import "./static/styles/navbarboot.css"

class Navigationbar extends React.Component {
  render() {
    return (
      <Navbar bg="light" expand="lg" id="custom-navbar">
        <Navbar.Brand className="navbarlink" href="/">
          WELLNESS Yoga
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link className="navbarlink" href="/">
              Home
            </Nav.Link>
            <Nav.Link className="navbarlink" href="/timetable">
              Timetable
            </Nav.Link>
            <Nav.Link className="navbarlink" href="/events">
              Events & Courses
            </Nav.Link>
            <Nav.Link className="navbarlink" href="/trainTeachers">
              Yoga-Teacher-Trainings
            </Nav.Link>
            {/* <Nav.Link className="navbarlink" href="/shop">
              Shop
            </Nav.Link> */}
            {/* <Nav.Link className="navbarlink" href="/contactus">
              Contact Us
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Navigationbar
