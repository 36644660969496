/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Navigation from "./navbarboot"
import "./layout.css"

const footerStyles = {
  display: `flex`,
  flexDirection: `column`,
  marginTop: `1rem`,
  textAlign: `center`,
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <title>{data.site.siteMetadata.title}</title>
      <Navigation />
      <div
      >
        <main>{children}</main>
        <footer style={footerStyles}>
          © {new Date().getFullYear()}
          {` `}
          <a href="https://wellness-yoga.vercel.app/">Wellness Yoga</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
